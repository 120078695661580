@import '../../styles/variable.scss';
.modal {
  font-family: $poppins;
  .modal-dialog {
    .center-title {
      text-align: center;
      width: 100%;
    }
    .modal-content {
      .modal-header {
        border-bottom: none;
        .modal-title {
          font-size: 1.3em;
        }
      }
      .modal-body {
        padding: 1em 1em;
        width: 100%;
      }
      .modal-footer {
        border: none;
      }
    }
  }
}
