@import "../../styles/variable.scss";
.Footer {
  background-color: $primary;
  text-align: center;
  padding: 1rem 0.5rem;
  .footer-text {
    color: white;
    font-family: $poppins;
    font-weight: 400;
    line-height: 0.625rem;
    font-size: 0.5rem;
    margin-bottom: 0;
  }
}
