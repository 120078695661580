@import "../../styles/variable.scss";

.SpecialOffers {
  background: $secondary;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1rem;
  .special-offers-title {
    font-family: $sourcesans;
    font-weight: 700;
    background-color: transparent;
    font-size: 1.25rem;
    margin-bottom: 0;
  }
  .special-offers-desc {
    font-family: $poppins;
    font-weight: 500;
    margin: 0.375rem 0;
    text-align: center;
    background-color: transparent;
    font-size: 0.75rem;
    b {
      font-weight: 700;
    }
  }
  .special-offers-toc {
    font-family: $poppins;
    font-weight: 500;
    background-color: transparent;
    font-size: 0.5rem;
    margin-bottom: 0;
  }
}
