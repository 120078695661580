.Coding {
  height: 100%;
  background-color: #f8f8f8;

  .back-container {
    display: flex;
    justify-content: space-between;
    padding-left: 0.75rem;
    margin-bottom: 0.875rem;
  }
  .overview-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
.footer-section {
  .about-us-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .about-mkd-container {
    background-color: transparent;
  }
  .branch-card-container {
    background-color: transparent;
  }
}
