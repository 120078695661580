.primary-btn {
  border: none;
  color: white;
  padding: 8px 24px;
  /* Button */
  background: #2a4878;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: background 0.5s ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:hover {
    color: white;
    padding: 8px 24px;
    /* Button */
    background: rgba(42, 72, 120, 0.6);
    border-radius: 2px;
  }
  &:active {
    transform: scale(0.9);
  }
}
.secondary-btn {
  border: none;
  color: white;
  padding: 8px 24px;
  /* Button */
  background: rgba(42, 72, 120, 0.6);
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: background 0.5s ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:hover {
    color: white;
    padding: 8px 24px;
    /* Button */
    background: #2a4878;
    border-radius: 2px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  &:active {
    transform: scale(0.9);
  }
}
.third-btn {
  border: none;
  color: #2a4878;
  padding: 8px 24px;
  /* Button */
  background: white;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: background 0.5s ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:hover {
    color: white;
    padding: 8px 24px;
    /* Button */
    background: rgba(42, 72, 120, 0.6);
    border-radius: 2px;
  }
  &:active {
    transform: scale(0.9);
  }
}
.disable-btn {
  border: none;
  background: rgb(101, 117, 145);
  padding: 8px 24px;
  /* Button */
  color: white;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
}
