@import "../../styles/variable.scss";

.OfficeBranchCard {
  min-width: 85px;
  min-height: 43px;
  text-align: center;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding-top: 5px;
  padding-bottom: 0.625rem;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  .line-style {
    width: 50px;
    box-sizing: border-box;
    height: 4px;
    background: #e1e1e1;
    border-radius: 15px;
    margin-bottom: 0.8rem;
  }
}
