.floating-alert {
  position: fixed;
  top: 10vh;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  color: white;
  padding: 1rem 3rem;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  animation: floatAnimation 3s ease-in-out infinite;

  .floating-reload {
    display: flex;
    flex-direction: column;
    align-items: center;

    .floating-text {
      font-size: 1rem;
      color: #000;
      white-space: nowrap;
      overflow: hidden;
      border-right: 2px solid transparent; /* Cursor effect */
      animation: typingAnimation 4s steps(30) infinite,
        cursorBlink 0.7s step-end infinite;
    }
    .refresh-button {
      padding: 10px 20px;
      background-color: rgba(42, 72, 120, 1);
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: rgba(42, 72, 120, 0.6);
        color: #fff;
        transform: scale(0.9);
      }
    }
  }
}

/* Keyframes animation for the floating effect */
@keyframes floatAnimation {
  0%,
  100% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(-20px);
  }
}

/* Keyframes animation for the typing effect */
@keyframes typingAnimation {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Keyframes animation for the cursor blink */
@keyframes cursorBlink {
  from,
  to {
    border-right-color: transparent;
  }
  50% {
    border-right-color: black;
  }
}
