@import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

//font
$test: "Pacifico", cursive;
$poppins: "Poppins", sans-serif;
$sourcesans: "Source Sans 3", sans-serif;

//color
$primary: rgba(42, 72, 120, 1);
$secondary: rgba(200, 216, 226, 1);
