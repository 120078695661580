@import '../../styles/variable.scss';
.TechPageComp {
  height: 100vh;
  display: grid;
  grid-template-rows: 3fr 5fr 1fr;
  background-color: $primary;
  color: $primary;
  padding-top: 1rem;
  text-align: center;
  .after-login-header {
    display: flex;
    justify-content: space-between;
    padding-left: 0.75rem;
    margin-bottom: 0.875rem;
    .dropdown {
      width: 100%;
      button {
        width: 80%;
        background-color: white;
        border: none;
        color: $primary;
      }
      .show {
        width: 80%;
        text-align: center;
      }
      .bold {
        font-weight: 700;
      }
    }
  }
  .before-login-container {
    h1 {
      color: white;
      margin-bottom: 0;
      font-family: $sourcesans;
    }
    p {
      color: white;
      margin-bottom: 0;
      font-family: $sourcesans;
    }
  }
  .tech-description-container {
    text-align: justify;
    padding: 0 1rem;
    p {
      color: white;
      font-size: 0.875rem;
      font-family: $poppins;
    }
  }
  .main-content-tech {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    height: 70vh;
    .main-content-container {
      overflow-y: scroll;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      width: 100%;
      height: 100%;
      background-color: #f8f8f8;
      padding: 1rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .technologies-card {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        gap: 1rem 1rem;
        max-width: 30rem;
        .learn-more-button {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .learn-more-span1 {
          grid-column: span 2;
        }
        .image-wrapper {
          background: #ffffff;
          overflow: hidden;
          border-radius: 7px;
          text-decoration: inherit;
          cursor: pointer;
          transition: background 250ms ease-in-out, transform 150ms ease;
          -webkit-appearance: none;
          -moz-appearance: none;
          &:hover {
            transform: scale(1.02);
          }
          &:active {
            transform: scale(0.99);
          }
          .solution-card-image {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 10rem;
            border-bottom: 1.5px solid rgba(0, 0, 0, 0.2);
            img {
              display: flex;
              align-items: center;
              justify-content: center;
              max-width: 100%;
              width: 100%;
              aspect-ratio: 16/9;
              object-fit: contain;
              width: 10rem;
              height: 10rem;
              // display: flex;
              // align-items: center;
              // justify-content: center;
              // width: 10rem;
              // padding: 1rem;
            }
          }
          .solution-card-image-packaging {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 14rem;
            min-height: 13rem;
            img {
              display: flex;
              align-items: center;
              justify-content: center;
              max-width: 100%;
              width: 100%;
              aspect-ratio: 16/9;
              object-fit: contain;
              width: 10rem;
              height: 10rem;
            }
          }
          .solution-card-text {
            padding: 0 0.19rem;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 3.1875rem;
            .h2-solution-card {
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 1rem;
              height: 100%;
              margin-bottom: 0;
              font-family: $sourcesans;
              font-weight: 500;
            }
          }
        }
      }
    }
    .lanjut-wa-card-container {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f8f8f8;
      .lanjut-ke-wa {
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0.8125rem;
        max-width: 21.5rem;
        height: 216px;
        border-radius: 6px;
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14);
        .line-style {
          width: 50px;
          box-sizing: border-box;
          height: 4px;
          background: #e1e1e1;
          border-radius: 15px;
          margin-bottom: 0.8rem;
        }
        .wa-card-title {
          width: 100%;
          background-color: transparent;
          color: black;
          text-align: start;
          margin-left: 1rem;
          margin-bottom: 1rem;
        }
        .wa-card-text {
          width: 100%;
          background-color: transparent;
          color: rgba(0, 0, 0, 0.6);
          font-family: $poppins;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          text-align: start;
          margin-left: 1rem;
          margin-bottom: 1.25rem;
        }
        .wa-card-button {
          width: 100%;
          display: flex;
          justify-content: space-evenly;
        }
      }
    }
    .product-detail-tech {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      height: 70vh;
      width: 100%;
      background-color: #f8f8f8;
      padding: 1rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .product-detail-card {
        border-radius: 6px;
        width: 90%;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        padding-bottom: 1.5rem;
        .product-detail-header {
          .image-product-detail {
            width: 12.25rem;
            height: 15.625rem;
          }
          .image-product-detail-coding {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 100%;
            width: 100%;
            aspect-ratio: 16/9;
            object-fit: contain;
            height: 10rem;
          }
          h2 {
            font-family: $sourcesans;
            font-style: normal;
            font-weight: 600;
            font-size: 2rem;
            line-height: 3rem;
          }
          p {
            font-family: $poppins;
            font-style: normal;
            font-weight: 400;
            font-size: 0.8rem;
            line-height: 1.1rem;
            color: #1c1919;
          }
        }
        .product-detail-footer {
          display: flex;
          flex-direction: column;
          align-items: center;
          .button-top {
            display: flex;
            margin-bottom: 1.25rem;
            max-width: 40rem;
            gap: 1rem;
          }
          .button-bottom {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .TechPageComp {
    .main-content-tech {
      .main-content-container {
        .technologies-card {
          .image-wrapper {
            .solution-card-image {
              position: relative;
              right: 0.25rem;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 475px) {
  .TechPageComp {
    .main-content-tech {
      .main-content-container {
        .technologies-card {
          .image-wrapper {
            .solution-card-image {
              position: relative;
              right: 0.5rem;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .SolutionTech {
    .technologies-card {
      .image-wrapper {
        .solution-card-image {
          position: relative;
          right: 0.75rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 425px) {
  .TechPageComp {
    .main-content-tech {
      .main-content-container {
        .technologies-card {
          .image-wrapper {
            .solution-card-image {
              position: relative;
              right: 1rem;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 400px) {
  .TechPageComp {
    .main-content-tech {
      .main-content-container {
        .technologies-card {
          .image-wrapper {
            .solution-card-image {
              position: relative;
              right: 1.25rem;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 390px) {
  .TechPageComp {
    .main-content-tech {
      .main-content-container {
        .technologies-card {
          .image-wrapper {
            .solution-card-image {
              position: relative;
              right: 1.5rem;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 375px) {
  .SolutionTech {
    .technologies-card {
      .image-wrapper {
        .solution-card-image {
          position: relative;
          right: 1.75rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 360px) {
  .TechPageComp {
    .main-content-tech {
      .main-content-container {
        .technologies-card {
          .image-wrapper {
            .solution-card-image {
              position: relative;
              right: 1.9rem;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-height: 740px) {
  .TechPageComp {
    .main-content-tech {
      .product-detail-tech {
        .product-detail-card {
          .product-detail-header {
            .image-product-detail {
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
