@import '../../styles/variable.scss';

.LandingPage1 {
  height: 100%;
  padding-bottom: calc(100vh - 840px);
  background: $primary;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .landing-page-image {
    margin-top: 1rem;
    width: 100%;
  }
  .welcome-text {
    font-family: $sourcesans;
    color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .or-style {
    font-size: 1.2rem;
    margin: 0.5rem 0;
    color: white;
  }
  .solution-card-click {
    padding: 0 0.4375rem;
    padding-top: 0.625rem;
    height: 23rem;
    width: 327px;
    color: inherit;
    text-decoration: inherit;
    display: inline-block;
    border: none;
    background: white;
    cursor: pointer;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1 solid black;
    box-shadow: 3px 3px 19px -6px rgba(0, 0, 0, 1);
    -webkit-box-shadow: 3px 3px 19px -6px rgba(0, 0, 0, 1);
    -moz-box-shadow: 3px 3px 19px -6px rgba(0, 0, 0, 1);
    border-radius: 10px;
    &:hover {
      transform: scale(1.02);
    }
    &:active {
      transform: scale(0.99);
    }
    .coding-label-style {
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
    }
    .coding-label-style-packaging {
      margin-bottom: 2rem;
    }
    .solution-image-container {
      margin-bottom: 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2px;
      width: 167px;
      .vj-logo-wraper {
        display: flex;
        justify-content: center;
        align-items: center;
        grid-column: span 3;
        padding-top: 0.8rem;
        .videojet-logo-card {
          width: 129px;
        }
      }

      .image-wrapper {
        min-height: 5.5rem;
        .solution-card-image {
          width: 4rem;
          height: 4rem;
        }
        .solution-card-image-packaging {
          width: 4.5rem;
          height: 1.78rem;
        }
        h2 {
          text-align: center;
          width: 100px;
          font-size: 0.75rem;
          margin-bottom: 0;
        }
      }
    }
    .arrow-style-coding {
      height: 26px;
      width: 26.67px;
      position: relative;
      left: 140px;
      bottom: 15px;
    }
    .arrow-style-pack {
      height: 26px;
      width: 26.67px;
      position: relative;
      left: 140px;
      bottom: 0;
    }
  }
  .solution-coding-height {
    height: 16rem;
  }
  /* @media only screen and (max-width: 280px) {
    .solution-card-click {
      height: auto;
      width: 270px;
      .solution-image-container {
        width: 17px;
        .image-wrapper {
          width: 100px;
          .solution-card-image {
            width: 0.2rem;
            height: 0.2rem;
          }
          h2 {
            display: inline;
            background-color: aqua;
            width: 1px;
            font-size: 0.2rem;
            margin-bottom: 0;
          }
        }
      }
    }
  }*/
  @media only screen and (max-height: 750px) {
    .solution-card-click {
      .solution-image-container {
        .image-wrapper {
          .solution-card-image {
            width: 4rem;
            height: 4rem;
          }
          h2 {
            text-align: center;
            width: 100px;
            font-size: 0.6rem;
            margin-bottom: 0;
          }
        }
      }
      .arrow-style-coding {
        height: 26px;
        width: 26.67px;
        position: relative;
        left: 140px;
      }
    }
  }
}

.FormContent {
  width: 100%;
  max-height: 600px;
  overflow-y: scroll;
  .button-on-form {
    display: flex;
    justify-content: center;
  }
}
