@import "../../styles/variable.scss";
.Header {
  padding-bottom: 1em;
  width: 100%;
  height: 5.06rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .logo-mkd {
    width: 139px;
    height: 49px;
  }
}
