@import "../../styles/variable.scss";
.SolutionTech {
  background-color: #f8f8f8;
  height: 100%;
  text-align: center;
  padding: 1.6875rem 1rem;
  padding-top: 1rem;

  // .back-container {
  //   display: none;
  //   span img {
  //     width: 40px;
  //     height: 40px;
  //     filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(215deg)
  //       brightness(50%) contrast(110%);
  //   }
  // }

  .technology-text {
    h1 {
      color: $primary;
    }
    p {
      color: $primary;
    }

    .tecnology-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .text-container {
        margin: auto;
      }
    }
  }
  .tech-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .technologies-card {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem 1rem;
      max-width: 30rem;
      .kitamura-card {
        grid-column: span 2;
      }
      .image-wrapper {
        background: #ffffff;
        overflow: hidden;
        border-radius: 7px;
        text-decoration: inherit;
        cursor: pointer;
        transition: background 250ms ease-in-out, transform 150ms ease;
        -webkit-appearance: none;
        -moz-appearance: none;
        &:hover {
          transform: scale(1.02);
        }
        &:active {
          transform: scale(0.99);
        }
        .solution-card-image {
          display: flex;
          align-items: center;
          justify-content: center;
          // min-width: auto;
          // max-height: 13rem;
          min-width: 14rem;
          min-height: 13rem;
          // img {
          //   object-fit: contain;
          //   width: 10rem;
          //   height: 10rem;
          // }
          border-bottom: 1.5px solid rgba(0, 0, 0, 0.2);
          img {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 100%;
            width: 100%;
            aspect-ratio: 16/9;
            object-fit: contain;
            width: 10rem;
            height: 10rem;
            // max-width: 100%;
            // aspect-ratio: 16/9;
            // object-fit: contain;
          }
        }
        .solution-card-image-packaging {
          min-width: 14rem;
          min-height: 13rem;
          img {
            width: 10rem;
            height: 5rem;
          }
        }
        .solution-card-text {
          padding: 0 0.19rem;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 3.1875rem;
          .h2-solution-tech {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.1rem;
            height: 100%;
            margin-bottom: 0;
            font-family: $sourcesans;
            font-weight: 500;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .SolutionTech {
    .back-container {
      display: flex;
      span {
        cursor: pointer;
      }
      span img {
        width: 40px;
        height: 40px;
        filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(195deg)
          brightness(40%) contrast(110%);
      }
      &:hover {
        filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(215deg)
          brightness(50%) contrast(70%);
      }
    }

    .tech-card-container {
      .technologies-card {
        .image-wrapper {
          .solution-card-image {
            position: relative;
            right: 0.25rem;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 475px) {
  .SolutionTech {
    .tech-card-container {
      .technologies-card {
        .image-wrapper {
          .solution-card-image {
            position: relative;
            right: 0.5rem;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .SolutionTech {
    .tech-card-container {
      .technologies-card {
        .image-wrapper {
          .solution-card-image {
            position: relative;
            right: 0.75rem;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 425px) {
  .SolutionTech {
    .tech-card-container {
      .technologies-card {
        .image-wrapper {
          .solution-card-image {
            position: relative;
            right: 1rem;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 400px) {
  .SolutionTech {
    .tech-card-container {
      .technologies-card {
        .image-wrapper {
          .solution-card-image {
            position: relative;
            right: 1.25rem;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 390px) {
  .SolutionTech {
    .tech-card-container {
      .technologies-card {
        .image-wrapper {
          .solution-card-image {
            position: relative;
            right: 1.5rem;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 375px) {
  .SolutionTech {
    .tech-card-container {
      .technologies-card {
        .image-wrapper {
          .solution-card-image {
            position: relative;
            right: 1.75rem;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 360px) {
  .SolutionTech {
    .tech-card-container {
      .technologies-card {
        .image-wrapper {
          .solution-card-image {
            position: relative;
            right: 1.9rem;
          }
        }
      }
    }
  }
}
