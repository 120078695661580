/* Container for the loading image */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust as needed */
  background-color: transparent important; /* Background color for the container */
}

/* Loading image */
.loading-image {
  width: 139px;
  height: 49px;
  animation: scaleAnimation 2s ease-in-out infinite;
}

@keyframes scaleAnimation {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
