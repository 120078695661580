@import "../../styles/variable.scss";

.CompanyOverview {
  background: #ffffff;
  box-shadow: 3px 7px 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin: 1.5625rem 0.2rem;
  max-width: 30rem;
  .company-overview-card {
    padding: 0.625rem 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .overview-item {
      flex: 1 1 98px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .overview-num {
        font-family: $sourcesans;
        color: rgba(51, 51, 51, 1);
        font-weight: 600;
        font-size: 1.1rem;
        line-height: 21px;
      }
      .overview-text {
        color: rgba(130, 130, 130, 1);
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 18px;
      }
    }
  }
}
