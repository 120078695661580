@import "../../styles/variable.scss";

.HeadOfficeCard {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .head-office-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 227px;
    height: 118px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    padding-top: 5px;
    .line-style {
      width: 50px;
      box-sizing: border-box;
      height: 4px;
      background: #e1e1e1;
      border-radius: 15px;
      margin-bottom: 0.8rem;
    }
    h1 {
      font-size: 0.875rem;
      margin-bottom: 0.5rem;
    }
    p {
      width: 193px;
      height: 49px;
      font-size: 0.75rem;
      text-align: justify;
      margin-bottom: 0;
    }
  }
}
