@import "../../styles/variable.scss";

.BranchCard {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  .branch-card-title {
    text-align: center;
    font-family: $sourcesans;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 21px;
  }
  .branch-cards {
    display: flex;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    gap: 1.5rem;
    padding: 1.32rem 0.5rem;
  }
}
@media only screen and (min-width: 650px) {
  .BranchCard {
    .branch-cards {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
