@import "../../styles/variable.scss";
.AboutUs {
  max-width: 30rem;
  .about-us-header {
    display: grid;
    grid-template-columns: 5fr 2fr;
    padding: 0 1.2rem;
    .markindo-text {
      display: flex;
      flex-direction: column;
      .text-about {
        font-family: $sourcesans;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
      }
      .markindo-logo-text {
        width: 50px;
        color: #2a4878;
        font-family: $sourcesans;
        font-style: normal;
        font-weight: 600;
        font-size: 2.25rem;
        line-height: 2.375rem;
      }
    }
    .markindo-logo {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .about-us-text {
    padding: 1.125rem 2rem;
    font-family: $poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-align: justify;
  }
}
